@media screen and (min-width: 1700px)
    #toolflyers 
        max-height: 498px
    .mkt_box_title
        height: 61px !important
        font-size: 30px !important
        padding-top: 8px !important
    .toolShipment
        font-size: 19px
    .effDate
        font-size: 19px
    .stylingTd
        font-size: 18px
@media screen and (max-width: 1700px)
    #toolflyers 
        max-height: 498px
    .mkt_box_title
        height: 61px !important
        font-size: 21px !important
        padding-top: 12px !important
    .toolShipment
        font-size: 17px
    .effDate
        font-size: 17px
    .stylingTd
        font-size: 16px
@media screen and (max-width: 1600px)
    #toolflyers 
        max-height: 460px
    .mkt_box_title
        height: 55px !important
        font-size: 21px !important
        padding-top: 9px !important
@media screen and (max-width: 1500px)
    #toolflyers 
        max-height: 437px
    .mkt_box_title
        height: 51px !important
        font-size: 21px !important
        padding-top: 9px !important
    .stylingTd
        font-size: 14px
@media screen and (max-width: 1400px)
    #toolflyers 
        max-height: 327px
    .mkt_box_title
        height: 41px !important
        font-size: 18px !important
        padding-top: 7px !important
@media screen and (max-width: 1200px) 
    #toolflyers 
        max-height: 271px
    .mkt_box_title
        height: 33px !important
        font-size: 15px !important
        padding-top: 5px !important
@media screen and (max-width: 992px) 
    #toolflyers 
        max-height: 198px
    .mkt_box_title
        height: 24px !important
        font-size: 11px !important
        padding-top: 4px !important
    .toolShipment
        font-size: 12px
    .effDate
        font-size: 12px
    .stylingTd
        font-size: 11px
@media screen and (max-width: 768px) 
    #toolflyers 
        max-height: 144px
    .mkt_box_title
        height: 19px !important
        font-size: 8px !important
        padding-top: 3px !important
    .toolShipment
        font-size: 9px
    .effDate
        font-size: 8px
    .stylingTd
        font-size: 8px
    
@media screen and (max-width: 575px) 
    #toolflyers 
        max-height: 290px
    .mkt_box_title
        height: 48px !important
        font-size: 20px !important
        padding-top: 8px !important
    
@media screen and (max-width: 375px) 
    #toolflyers 
        max-height: 180px
    .mkt_box_title
        height: 28px !important
        font-size: 11px !important
        padding-top: 7px !important
        

      
    